import { getPropsForHomepage } from "clariah/utils/staticHelpers";
import { HomePage } from "clariah/containers/HomePage";

export async function getStaticProps({ preview = false, locale }) {
  return await getPropsForHomepage({ locale, preview });
}

const PageToExport = (props) => <HomePage {...props} />;

export default PageToExport;
